import React from 'react'
import { connect } from 'react-redux'
import { motion } from 'framer-motion'

import './Navigation.scss'

const Navigation = props => {
	const navVariants = {
		initial: {
			opacity: 0
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5
			}
		}
	}
	return (
		<motion.nav
			className='navigation'
			variants={navVariants}
			initial='initial'
			whileInView='animated'>
			<ul id='menu' className='navigation__links'>
				{props.links.map((link, index) => {
					return (
						<li data-menuanchor={link.dataAnchor} key={index}>
							<a href={link.href}>{link.anchor}</a>
						</li>
					)
				})}
			</ul>
		</motion.nav>
	)
}

const mapStateToProps = state => {
	return {
		links: state.navigationReducer.links
	}
}

export default connect(mapStateToProps)(Navigation)
