import React, { useRef } from 'react'
import { motion } from 'framer-motion'

import Footer from '../../Footer/Footer'

import './Contacts.scss'

const Contacts = () => {
	const contactVariants = {
		initial: {
			borderWidth: '0px',
			borderStyle: 'double',
			borderColor: '#db2422'
		},
		animated: {
			borderWidth: '10px',
			borderStyle: 'double',
			borderColor: '#db2422',
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5
			}
		}
	}
	return (
		<div className='contacts'>
			<motion.div
				className='contacts__element'
				variants={contactVariants}
				initial='initial'
				whileInView='animated'>
				<span>Телеграмм: </span>
				<a href='https://t.me/Andrey_SSS'>t.me/Andrey_SSSa</a>
			</motion.div>
			<motion.div
				className='contacts__element'
				variants={contactVariants}
				initial='initial'
				whileInView='animated'>
				<span>Телефон: </span>{' '}
				<a href='tel:+79037552612'>+7 (903) 755-26-12</a>
			</motion.div>
			<motion.div
				className='contacts__element'
				variants={contactVariants}
				initial='initial'
				whileInView='animated'>
				<span>ВКонтакте: </span>
				<a href='https://vk.com/minifootballuzao'>
					vk.com/minifootballuzao
				</a>
			</motion.div>
		</div>
	)
}

export default Contacts
