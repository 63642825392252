import React from 'react'
import { connect } from 'react-redux'
import { color, motion } from 'framer-motion'

import Navigation from './Navigation/Navigation'

import './Header.scss'

import logo from '../assets/img/logo.png'

const Header = props => {
	const buttonVariants = {
		initial: {
			color: '',
			border: '5px solid $red-1',
			outline: '2px solid $brown-1'
		},
		animated: {
			border: '5px solid #fff',
			outline: '2px solid #db2422',
			backgroundColor: '#db2422',
			color: '#fff',
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
				ease: 'linear',
				repeat: 'Infinity'
			}
		}
	}
	return (
		<header className='header'>
			<div className='navigation__burger'>
				<span></span>
			</div>
			<img className='header__logo' src={props.logo} alt='' />
			<h1 className='header__title'>{props.title}</h1>
			<a
				className='header__phone-number'
				href={'tel:' + props.phoneNumber}>
				{props.phoneNumber}
			</a>
			<Navigation />
			<motion.a
				href='https://t.me/UNION_Moscow_bot'
				className='header__button'
				style={{
					border: '5px solid white'
					// color: '#ece3d2',
					// backgroundColor: '#db2422'
				}}
				animate={{
					type: 'spring',
					border: '5px solid #db2422'
					// color: '#db2422',
					// backgroundColor: '#ece3d2'
				}}
				transition={{
					ease: 'linear',
					duration: 1,

					repeat: Infinity,
					repeatType: 'mirror'
				}}>
				Играть с нами
			</motion.a>
		</header>
	)
}

const mapStateToProps = state => {
	return {
		title: state.headerReducer.title,
		logo: state.headerReducer.logo,
		phoneNumber: state.headerReducer.phoneNumber
	}
}

export default connect(mapStateToProps)(Header)
