import posterBackgroundImage from '../../assets/img/luzhniki2.JPG'

const initialState = {
	poster: {
		backgroundImage: posterBackgroundImage,
		title: 'Поиграть в футбол в Москве в Лужникаx'
	},
	textSections: [
		{
			title: '',
			content: ''
		}
	]
}

export default function sectionsReducer(state = initialState, action) {
	return state
}
