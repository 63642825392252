import logo from '../../assets/img/logo.png'

const initialState = {
	links: [
		{
			dataAnchor: 'main',
			anchor: 'Главная',
			href: '#main'
		},
		{
			dataAnchor: 'about',
			anchor: 'О нас',
			href: '#about'
		},
		{
			dataAnchor: 'schedule',
			anchor: 'Расписание',
			href: '#schedule'
		},
		{
			dataAnchor: 'contacts',
			anchor: 'Контакты',
			href: '#contacts'
		}
	]
}

export default function navigationReducer(state = initialState, action) {
	return state
}
