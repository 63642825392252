import React from 'react'
import { connect } from 'react-redux'

import Header from './Header/Header'
import Navigation from './Header/Navigation/Navigation'
import Poster from './Sections/Poster/Poster'
import TextSection from './Sections/TextSection/TextSection'
import TextImageSection from './Sections/TextImageSection/TextImageSection'
import Footer from './Footer/Footer'

import './App.scss'

const App = props => {
	return (
		<div className='app'>
			<Header />
			<div id='fullpage'>
				<Poster section={props.poster} />
				<TextSection section={props.textSections[0]} />
				<TextImageSection section={props.textImageSections[0]} />
				<TextSection section={props.textSections[1]} />
				{/*<Footer />*/}
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		poster: state.appReducer.poster,
		textSections: state.appReducer.textSections,
		textImageSections: state.appReducer.textImageSections
	}
}

export default connect(mapStateToProps)(App)
