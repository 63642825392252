import React from 'react'

import { connect } from 'react-redux'

import './Footer.scss'

const Footer = props => {
	return (
		<footer className='footer section' data-anchor='footer'>
			<span className='footer__developed-text'>
				{props.developedText}{' '}
				<a href={props.savaHref} className='footer__sava'>
					{props.savaText}
				</a>
			</span>
			<span className='footer__date'>{props.date}</span>
		</footer>
	)
}

const mapStateToProps = state => {
	return {
		developedText: state.footerReducer.developedText,
		savaText: state.footerReducer.savaText,
		savaHref: state.footerReducer.savaHref,
		date: state.footerReducer.date
	}
}

export default connect(mapStateToProps)(Footer)
