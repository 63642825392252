import React from 'react'
import { connect } from 'react-redux'
import { motion } from 'framer-motion'

import './Poster.scss'

import luzhniki from '../../assets/img/luzhniki2.JPG'

const Poster = props => {
	const posterVariants = {
		initial: {
			opacity: 0,
			width: 0
		},
		animated: {
			width: '100%',
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5
			}
		}
	}
	return (
		<section className='poster section' data-anchor='main' id='main'>
			<motion.h1
				className='poster__title'
				variants={posterVariants}
				initial='initial'
				whileInView='animated'>
				{props.section.title}
			</motion.h1>
		</section>
	)
}
const mapStateToProps = state => {
	return {
		title: state.sectionsReducer.poster.title,
		backgroundImage: state.sectionsReducer.poster.backgroundImage
	}
}

export default connect(mapStateToProps)(Poster)
