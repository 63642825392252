import React from 'react'

import './Map.scss'

const Map = props => {
	return (
		<div className='map'>
			<h2>sdaasd</h2>
		</div>
	)
}

export default Map
