import React from 'react'
import ReactFragment from 'react'

import Schedule from '../../Components/Schedule/Schedule'
import Contacts from '../../Components/Contacts/Contacts'
import Map from '../../Components/Map/Map'

import posterBackgroundImage from '../../assets/img/luzhniki2.JPG'
import scheduleBackgroundImage from '../../assets/img/luzhniki-1.jpg'
import About from '../../Components/About/About'

const initialState = {
	poster: {
		dataAnchor: 'poster',
		id: 'posterSection',
		backgroundImage: posterBackgroundImage,
		title: 'Поиграть в футбол в Москве на полях Лужников'
	},
	textSections: [
		{
			dataAnchor: 'about',
			id: 'aboutSection',
			title: 'Поиграть в футбол без команды',
			content: (
				<About>
					<div>
						<p>
							Мы знаем, что многие ищут хороший вариант,чтобы
							вечером в будни после работы поиграть в футбол.
							<br /> Да почему именно вечером в будни? В футбол и
							в выходной день очень интересно поиграть! В общем,
							один или с другом кто-то открывает поисковик и ищет,
							с кем и где можно поиграть в футбол... Ну, или
							любительский футбол в Москве. Ну вот Вы и нашли, что
							искали.
						</p>
						<p>
							Приглашаем Вас присоединиться к нашему дружному
							союзу любителей большого футбола.
						</p>
					</div>
					<div>
						<h2>Играть в футбол в Лужниках. </h2>
						<p>
							С нами можно отлично потренироваться на хорошем
							искусственном поле в Лужниках. Тренировки в
							футбольной секции для взрослых проходят в формате
							разминки и последующих интересных игр мини-турнира.
							Мы играем в футбол 8 на 8, и в нашей дружной команде
							будут всегда рады новичкам, которые играют в
							фаерплэй и красивый футбол. Нас много, и мы с разным
							уровнем игры! Мы готовы погонять мяч и летом, и
							зимой... Да! Мы играем в футбол круглый год.
							Позитивный настрой на наших тренировках позволяет
							поддерживать высокую спортивную форму и интересную
							игру на площадке с искусственной травой. А в
							Лужниках искусственные поля очень хорошие: мягкие и
							подогревом. Конечно же, есть раздевалки и душевые. В
							общем, созданы отличные условия для тренировок как
							полевых игроков, так и вратарей.
						</p>
					</div>
					<div>
						<h2>Любительский футбол в Москве.</h2>
						<p>
							Теперь Вы знаете, что для того, чтобы играть в
							футбол не нужно долго искать и думать: где и с кем,
							а просто походите к нам на игры и будете приятно
							удивлены той атмосферой, что царит на играх нашего
							футбольного клуба. Играть в футбол? Мы очень любим и
							делаем это с большим удовольствием. Ведь
							любительский футбол предполагает взаимное уважение и
							одновременно бескомпромиссную борьбу на поле, потому
							что здесь играют настоящие фанаты этого вида спорта.
							Постоянно на протяжении нескольких лет, проводя
							товарищеские матчи и турниры, мы совершенствуем наше
							умение управляться с мячом, играя в футбол!
						</p>
					</div>
				</About>
			)
		},
		{
			dataAnchor: 'contacts',
			id: 'contactsSection',
			title: 'Контакты',
			content: <Contacts />
		}
	],
	textImageSections: [
		{
			dataAnchor: 'schedule',
			id: 'scheduleSection',
			title: 'Расписание',
			backgroundImage: scheduleBackgroundImage,
			content: <Schedule />
		},
		{
			dataAnchor: 'map',
			id: 'mapSection',
			title: 'Как добраться',
			content: <Map />
		}
	]
}

export default function appReducer(state = initialState, action) {
	return state
}
