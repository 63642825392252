import { combineReducers } from 'redux'

import appReducer from './appReducer'
import headerReducer from './headerReducer'
import navigationReducer from './navigationReducer'
import sectionsReducer from './sectionsReducer'
import footerReducer from './footerReducer'

export default combineReducers({
	appReducer,
	headerReducer,
	navigationReducer,
	sectionsReducer,
	footerReducer
})
