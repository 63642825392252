import React from 'react'
import { motion } from 'framer-motion'

import './TextSection.scss'

const TextSection = props => {
	const sectionTitleVariants = {
		initial: {
			opacity: 0
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5
			}
		}
	}
	return (
		<section
			className='text-section section fp-auto-height-responsive'
			data-anchor={props.section.dataAnchor}>
			<motion.h2
				className='text-section__title'
				variants={sectionTitleVariants}
				initial='initial'
				whileInView='animated'>
				{props.section.title}
			</motion.h2>
			<div className='text-section__content'>{props.section.content}</div>
		</section>
	)
}

export default TextSection
