import logo from '../../assets/img/logo 2.png'

const initialState = {
	title: 'СОЮЗ',
	logo: logo,
	phoneNumber: '+7 (903) 755-26-12'
}

export default function headerReducer(state = initialState, action) {
	return state
}
