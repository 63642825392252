import React from 'react'
import { motion } from 'framer-motion'

import './Schedule.scss'

const Schedule = props => {
	const scheduleVariants = {
		initial: {
			opacity: 0
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5
			}
		}
	}
	return (
		<div className='schedule'>
			<motion.div
				className='schedule__day'
				variants={scheduleVariants}
				initial='initial'
				whileInView='animated'>
				<span className='schedule__day-title'>Четверг</span>
				<span className='schedule__day-time'>22:30</span>
			</motion.div>
			<motion.div
				className='schedule__day'
				variants={scheduleVariants}
				initial='initial'
				whileInView='animated'>
				<span className='schedule__day-title'>Воскресенье</span>
				<span className='schedule__day-time'>13:00</span>
			</motion.div>
			{/*<div className='schedule__day'>*/}
			{/*	<span className='schedule__day-title'>Четверг</span>*/}
			{/*	<span className='schedule__day-time'>20:00</span>*/}
			{/*	<span className='schedule__day-field'>Поле 11</span>*/}
			{/*</div>*/}
			{/*<div className='schedule__day'>*/}
			{/*	<span className='schedule__day-title'>Воскресенье</span>*/}
			{/*	<span className='schedule__day-time'>22:00</span>*/}
			{/*	<span className='schedule__day-field'>Поле 10</span>*/}
			{/*</div>*/}
		</div>
	)
}

export default Schedule
